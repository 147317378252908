<template>
  <div class="provider-requests">
    <b-modal
      id="create-dispute-modal"
      v-model="serviceHistoryProps.viewDisputeModal"
      :class="disputeObject ? 'height-auto' : 'hide-dispute-details-footer'"
      :title="$t('Dispute Details')"
      :ok-title="$t('Submit')"
      :no-close-on-backdrop="true"
      ok-only
      @hidden="hideDisputeModal"
      @ok="submitServiceDispute"
    >
      <b-col
        v-if="!disputeObject"
        cols="12"
        md="12"
      >
        <b-form-group
          :label="$t('Dispute Name')"
          class="price-range-dropdown"
        >
          <v-select
            v-model="serviceHistoryProps.currentDispute"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="disputeOptions"
            :reduce="val => val.value"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group
          v-if="serviceHistoryProps.currentDispute === 'Others'"
          :label="$t('Dispute Reason')"
          label-for="disputeReason"
        >
          <b-form-input
            id="disputeReason"
            v-model="serviceHistoryProps.disputeReason"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Dispute Name') }}:</span> {{ serviceHistoryProps.disputeObject.dispute_name }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Date') }}:</span> {{ serviceHistoryProps.disputeObject.created_at }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Status') }}:</span> {{ serviceHistoryProps.disputeObject.status }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments By') }}:</span> {{ serviceHistoryProps.disputeObject.comments_by }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments') }}:</span> {{ serviceHistoryProps.disputeObject.comments }}
        </p>
      </b-col>
    </b-modal>
    <div
      v-if="providerAllRequest && providerAllRequest.length > 0"
      class="wrap-request-infos mb-2"
    >
      <div class="grid-view d-flex mt-0">
        <request-cards
          v-for="(props, index) of providerAllRequest"
          :key="index"
          :props="props"
          :that="that"
        />
      </div>
    </div>
    <b-modal
      id="upcomingServiceModal"
      v-model="serviceHistoryProps.upcomingServiceDetailVisible"
      :no-close-on-backdrop="true"
      :title="$t('Service Details')"
      :hide-footer="true"
      size="lg"
      @hidden="hideUpcomingServiceModal"
    >
      <view-service
        v-if="serviceHistoryProps.tripHistoryObject"
        :show-re-sechdule="true"
        :service-history-props="{ tripHistoryObject: serviceHistoryProps.tripHistoryObject }"
        @request-schedule="rescheduleRequest"
        @upload-image-service="uploadImageService"
      />
    </b-modal>
    <b-modal
      id="requestHistoryModal"
      v-model="serviceHistoryProps.viewHistoryModal"
      :no-close-on-backdrop="true"
      :title="$t('Request History')"
      :hide-footer="true"
      @hidden="hideRequestHistoryModal"
    >
      <app-timeline>
        <app-timeline-item
          v-for="(item, index) of serviceHistoryProps.requestHistoryList"
          :key="index"
          :title="item.status + ' at ' + item.created_at"
          :variant="index == serviceHistoryProps.requestHistoryList.length - 1 ? 'secondary' : 'primary'"
        />
      </app-timeline>
    </b-modal>
    <b-modal
      id="createServiceModal"
      v-model="showCreateNewService"
      class="provider-requests-order-modal"
      :no-close-on-backdrop="true"
      hide-footer
      :title="$t('Create Service')"
      size="xl"
      @hidden="serviceRequestModalClosed"
    >
      <order-creation
        @create-order="orderCreated"
        @save-updated-service="saveUpdatedService"
      />
    </b-modal>
    <b-modal
      id="updateRequestModal"
      v-model="serviceHistoryProps.viewUpdateRequestModal"
      :no-close-on-backdrop="true"
      :title="$t('Update Request')"
      @hidden="hideUpdateRequestModal"
      @ok="updateRequestStatus"
    >
      <div class="wrap-update-request">
        <div class="col-md-12">
          <b-form-group :label="$t('Status')">
            <v-select
              v-model="serviceHistoryProps.updateRequest.selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="requestStatusList"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-form-group>
          <b-form-group
            v-if="serviceHistoryProps.updateRequest.selectedStatus === 'SCHEDULED'"
            :label="$t('Schedule time')"
          >
            <custom-calender
              ref="calenderRef"
              :hide-submit="true"
              :timing="storeCurrentTimings"
              :all-timings="providerTimings"
              :store-slot-info="providerTimings"
            />
          </b-form-group>
          <b-form-group
            v-if="['ASSIGNED', 'PICKEDUP', 'DROPPED'].includes(serviceHistoryProps.updateRequest.selectedStatus)"
            :label="$t('Agents')"
          >
            <v-select
              v-model="serviceHistoryProps.updateRequest.assignedAgent"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="agentListProvider"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-form-group>
          <b-form-group
            v-if="serviceHistoryProps.updateRequest.selectedStatus === 'PICKEDUP'"
            :label="$t('Before Image')"
          >
            <image-input
              class="back-image"
              @input-file="inputFile"
            />
          </b-form-group>
          <b-form-group
            v-if="serviceHistoryProps.updateRequest.selectedStatus === 'DROPPED'"
            :label="$t('After Image')"
          >
            <image-input
              class="back-image"
              @input-file="inputFile"
            />
          </b-form-group>
          <b-form-group :label="$t('Notes')">
            <b-form-input
              v-model="serviceHistoryProps.updateRequest.notes"
              type="text"
            />
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="assignOrderToAgentModal"
      v-model="showAssignOrderToAgent"
      hide-footer
      :no-close-on-backdrop="true"
      :title="$t('Assign Order')"
      size="xl"
      @hidden="showAssignOrderToAgent = false"
    >
      <find-agents
        :order-id="serviceHistoryProps.activeAssignOrder"
        @show-on-map="showOnMap"
      />
    </b-modal>
    <b-modal
      v-model="showAgentOnMap"
      hide-footer
      :no-close-on-backdrop="true"
      :title="$t('Agent Location')"
      size="xl"
      @hidden="showAgentOnMap = false"
    >
      <agent-location :agent-id="showOnMapAgent" />
    </b-modal>
    <b-card>
      <b-row>
        <div class="col-12">
          <div class="d-md-flex justify-content-between px-md-2 mb-3">
            <div>
              <div class="row d-md-flex mb-2 mb-md-0 justify-content-md-start align-content-center align-items-center d-sm-flex justify-content-sm-between">
                <div class="col-12 col-md-auto">
                  <b-input-group>
                    <template #append>
                      <div class="d-flex align-items-center" @click="copyUrl" style="border: 1px solid #d8d6de; border-top-right-radius: 6px; border-bottom-right-radius: 6px; border-left: 2px solid #d8d6de;">
                        <b-img
                          style="padding: 5px"
                          width="35"
                          height="35"
                          :src="require('@/assets/images/app-icons/copy_new.png')"
                        />
                      </div>
                    </template>
                    <b-form-input :value="storeUrl" class="fs-p875rem" readonly />
                  </b-input-group>
                </div>
                <div class="col-auto pr-sm-0 mt-1 mt-md-0">
                  <b-button block-sm variant="light" size="sm" class="border-dark" :href="storeUrl" target="_blank">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="pr-md-1"><img :src="`${$apiUrl}/images/icons/visit.svg`" class="store-profile-3-icons" /></div>
                      <div class="fs-p875rem">{{ $t('VISIT') }}</div>
                    </div>
                  </b-button>
                </div>
                <div class="col-auto pr-sm-0 mt-1 mt-md-0">
                  <b-button variant="light" size="sm" class="border-dark" @click="$superApp.actions.sharePage('MOM', '', storeUrl)">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="pr-md-1"><img :src="`${$apiUrl}/images/icons/share.svg`" class="store-profile-3-icons" /></div>
                      <div class="fs-p875rem">{{ $t('SHARE') }}</div>
                    </div>
                  </b-button>
                </div>
                <div class="col-auto pr-sm-0 mt-1 mt-md-0">
                  <b-button variant="light" size="sm" class="border-dark" @click="generateQr">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="pr-md-1"><img :src="`${$apiUrl}/images/icons/qr-code.svg`" class="store-profile-3-icons" /></div>
                      <div class="fs-p875rem">{{ $t('QR CODE') }}</div>
                    </div>
                  </b-button>
                </div>
              </div>
            </div>
            <div>
              <div class="row d-md-flex justify-content-end">
                <div class="col-12 col-md-auto">
                  <b-input-group class="input-group-merge">
                    <b-input-group-append style="border: 1px solid #d8d6de; border-top-left-radius: 6px; border-bottom-left-radius: 6px; border-right: 2px solid #d8d6de">
                      <b-img
                        width="35"
                        height="35"
                        style="padding: 5px"
                        :src="`${$apiUrl}/images/icons/filter.svg`"
                      />
                    </b-input-group-append>
                    <select
                      class="form-control pl-1"
                      @change="getFiltered"
                    >
                      <option value="">
                        All status
                      </option>
                      <option
                        v-for="(item, index) of requestStatusList.filter(x => x.value)"
                        :key="index"
                        :value="item.value"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                  </b-input-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-col cols="12">
          <good-data-table
            ref="myRequestsGrid"
            :columns="myRequestColumn"
            :total-column="'response.responseData.service.total'"
            :data-column="'response.responseData.service.data'"
            :api-endpoint="apiEndpoint"
            :button-label="$t('Add Booking')"
            :no-serial="true"
            @add-new-info="createServiceModal"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { BCard, BRow, BCol, BModal, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import vSelect from 'vue-select'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import moment from 'moment'
import ImageInput from '../users/shared/ImageInput.vue'
import { useProviderUi } from './useProvider'
import OrderCreation from './shared/OrderCreation.vue'
import ViewService from './my-services/ViewService.vue'
import RequestCards from './shared/RequestCards.vue'
import FindAgents from './FindAgents.vue'
import CustomCalender from '../users/shared/CustomCalender.vue'
import AgentLocation from '../shared/AgentLocation.vue'
import { useUserUi } from '../users/useUser'
import { isEmpty, getStatusTextForRequest } from '@/utilities'
export default {
  components: {
    CustomCalender,
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCol,
    GoodDataTable,
    ViewService,
    BModal,
    BFormGroup,
    FindAgents,
    BFormInput,
    vSelect,
    ImageInput,
    OrderCreation,
    RequestCards,
    AgentLocation,
  },
  data() {
    return {
      storeUrl: `${window.location.origin}/user/service/provider/${window.SuperApp.getters.userInfo().slug}`,
      qrCodeUrl: `${jwtDefaultConfig.baseEndpoint}${window.SuperApp.getters.userInfo().qrcode_url}`,
      qrCodePage: `${window.location.origin}/user/provider/${window.SuperApp.getters.userInfo().slug}/${window.SuperApp.getters.userInfo().id}`,
      myRequestColumn: [
        {
          label: 'Booking Id',
          field: 'booking_id',
        },
        {
          label: 'Date',
          field: 'assigned_time',
          rField: 'assigned_time_text',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
          useRenderer: true,
          useResolver: true,
          renderer: props => {
            if (props.assigned_time) {
              return props.assigned_time
            }
            return 'N/A'
          },
        },
        {
          label: 'Customer',
          field: 'first_name',
          resolvePath: 'user.first_name',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
          useRenderer: true,
          useResolver: true,
          useHtml: true,
          excelHtml: props => {
            if (props.user) {
              return `${props.user.first_name} ${props.user.last_name}`
            }
            return ''
          },
          renderer: props => {
            if (props.user) {
              return `<div class="d-flex align-items-center justify-content-center">
                        <img src="${window.SuperApp.helper.image(props.user.picture)}" onerror="this.src = '${
                window.SuperApp.constants.noImageAvailable
              }'" style="width: 40px; height: 40px; border-radius: 10%;" />
                        <div class="wrap-info">
                            <p class="mb-0 ml-1">${props.user.first_name} ${props.user.last_name}</p>
                            <p class="mb-0 ml-1">${props.user.mobile ? props.user.mobile : ''}</p>
                        </div>
                      </div>`
            }
            return ''
          },
        },
        {
          label: 'Service Location',
          field: 's_address',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
          sortable: false,
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.s_address) {
              return "USER"
            }
            if (props.items && props.items[0] && props.items[0].provider) {
              return "PROVIDER"
            }
            return 'NA'
          },
        },
        {
          label: 'Amount',
          field: 'price',
          rField: 'price_text',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
          useResolver: true,
          useRenderer: true,
          renderer: props => this.getServicePrice(props),
        },
        {
          label: 'Status',
          field: 'status',
          rField: 'status_text',
          align: 'center',
          useRenderer: true,
          useResolver: true,
          renderer: props => getStatusTextForRequest(props.status),
        },
        {
          label: 'Agent',
          field: 'agent_name',
          align: 'center',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
          resolvePath: 'agent.name',
          useRenderer: true,
          useResolver: true,
          useHtml: true,
          excelHtml: props => {
            if (props.agent) {
              return `${props.agent.name}`
            }
            return ''
          },
          renderer: props => {
            if (props.agent) {
              return `<div class="d-flex align-items-center text-center justify-content-center">
                        <img src="${window.SuperApp.helper.image(props.agent.picture)}" onerror="this.src = '${
                window.SuperApp.constants.noImageAvailable
              }'" style="width: 40px; height: 40px; border-radius: 10%;" />
                        <div class="wrap-info">
                            <p class="mb-0 ml-1">${props.agent.name}</p>
                        </div>
                      </div>`
            }
            return ''
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          buttons: [
            {
              iconHtml: this.$helpers.getIcons('edit'),
              image: this.$helpers.getIcons('edit'),
              title: 'Edit Service',
              text: 'Edit',
              action: props => {
                this.editService(props)
              },
            },
            {
              icon: 'track',
              title: 'Track Order',
              image: `${this.$apiUrl}/images/icons/track2.svg`,
              action: props => {
                this.$router.push(`/provider/service/agent/view/${props.id}`)
              },
            },
            {
              icon: 'assignment',
              title: 'Assign Agent',
              image: `${this.$apiUrl}/images/icons/assign.svg`,
              action: props => {
                this.showAssignOrderToAgent = true
                this.serviceHistoryProps.activeAssignOrder = props.id
                this.serviceHistoryProps.currentServiceForDispute = props
                // this.serviceHistoryProps.updateRequest.selectedStatus = 'ASSIGNED'
                // this.$router.push(`/shop/order/find-agent/${props.id}`)
              },
            },
            {
              title: 'View',
              image: `${this.$apiUrl}/images/icons/info.svg`,
              action: props => {
                this.viewServiceHistory(props)
              },
            },
            // {
            //   title: 'Dispute',
            //   image: this.$helpers.getIcons('dispute'),
            //   action: props => {
            //     this.createServiceDispute(props)
            //   },
            // },
            // {
            //   image: require('@/assets/images/app-icons/1history.png'),
            //   title: 'History',
            //   action: props => {
            //     this.viewRequestHistory(props)
            //   },
            // },
            {
              image: `${this.$apiUrl}/images/icons/update.svg`,
              title: 'Update',
              action: props => {
                this.viewUpdateRequestModal(props)
              },
            },
            {
              icon: 'download-invoice',
              title: 'Download Invoice',
              image: require('@/assets/images/app-icons/document.png'),
              action: props => {
                if (props.payment && props.payment.invoice_url) {
                  window.open(window.SuperApp.helper.image(props.payment.invoice_url))
                }
              },
            },
          ],
        },
      ],
      providerAllRequest: [],
      showAssignOrderToAgent: false,
      serviceHistoryProps: {
        activeAssignOrder: null,
        tripHistoryObject: null,
        currentServiceForDispute: {},
        upcomingServiceDetailVisible: false,
        viewDisputeModal: false,
        currentDispute: '',
        disputeObject: {},
        viewHistoryModal: false,
        requestHistoryList: [],
        viewUpdateRequestModal: false,
        updateRequest: {
          selectedStatus: '',
          notes: '',
          scheduleDate: '',
          assignedAgent: '',
          beforePickup: null,
        },
      },
      requestStatusList: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Accepted',
          value: 'ACCEPTED',
        },
        {
          label: 'Scheduled',
          value: 'SCHEDULED',
        },
        {
          label: 'Started',
          value: 'STARTED',
        },
        {
          label: 'Assigned',
          value: 'ASSIGNED',
        },
        {
          label: 'Arrived',
          value: 'ARRIVED',
        },
        {
          label: 'Pickup',
          value: 'PICKEDUP',
        },
        {
          label: 'Dropped',
          value: 'DROPPED',
        },
        {
          label: 'Completed',
          value: 'COMPLETED',
        },
        {
          label: 'Cancelled',
          value: 'CANCELLED',
        },
      ],
      disputeOptions: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Customer denied to pay amount',
          value: 'Customer denied to pay amount',
        },
        {
          label: 'User provided wrong service information',
          value: 'User provided wrong service information',
        },
        {
          label: 'User neglected to pay additional charge',
          value: 'User neglected to pay additional charge',
        },
        {
          label: 'User provided less amount',
          value: 'User provided less amount',
        },
        {
          label: 'Others',
          value: 'Others',
        },
      ],
      agentListProvider: [],
      showCreateNewService: false,
      showAgentOnMap: false,
      showOnMapAgent: 0,
      providerTimings: [],
      storeCurrentTimings: null,
    }
  },
  computed: {
    that() {
      return this
    },
    disputeObject() {
      return this.serviceHistoryProps.disputeObject && this.serviceHistoryProps.disputeObject.id
    },
    apiEndpoint() {
      return window.SuperApp.getters.isAgent() ? `/provider${window.SuperApp.getters.isAgent() ? '/agent' : ''}/requests` : '/provider/accepted_requests'
    },
    isAgent() {
      return window.SuperApp.getters.isAgent()
    },
    storeTimings() {
      if (!this.providerTimings) return null
      const currentDay = moment().format('ddd').toUpperCase()
      return this.providerTimings.find(x => x.store_day === currentDay)
    },
  },
  mounted() {
    this.getProviderRequestList()
    eventBus.$emit('reset-order-provider-notification')
    eventBus.$on('refresh-provider-accept-grid', this.refreshGridArea)
    eventBus.$on('refresh-provider-accept-bars', this.getProviderRequestList)
    if (!this.isAgent) {
      this.getProviderTimings()
      const { providerAgentList } = useProviderUi()
      providerAgentList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.agentListProvider = data.responseData.data.map(x => ({
              label: x.name,
              value: x.id,
            }))
            this.agentListProvider.unshift({
              label: 'Select',
              value: '',
            })
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    }
  },
  destroyed() {
    eventBus.$off('refresh-provider-accept-bars')
    eventBus.$off('refresh-provider-accept-grid', this.refreshGridArea)
  },
  methods: {
    moment, isEmpty,
    storeCurrentTimingsInfo() {
      if (!this.providerTimings) return null
      if (this.storeTimings) return this.storeTimings
      return this.providerTimings[0]
    },
    getProviderTimings() {
      const { getProviderTimings } = useUserUi()
      const { slug } = window.SuperApp.getters.userInfo()
      getProviderTimings(`?provider_id=${slug}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerTimings = data.responseData
            this.storeCurrentTimings = this.storeCurrentTimingsInfo()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    generateQr() {
      const { generateQr } = useProviderUi()
      showLoader()
      const formData = new FormData()
      formData.append('provider_id', window.SuperApp.getters.userInfo().id)
      generateQr(formData)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            window.open(this.qrCodePage)
          } else {
            showDangerNotification(this, 'Failed to generate QR')
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    serviceRequestModalClosed() {
      this.showCreateNewService = false
      this.$store.commit('app-ecommerce/UPDATE_PROVIDER_SERVICE_REQUEST', null)
      this.$store.commit('app-ecommerce/UPDATE_EDIT_PROVIDER_SERVICE_REQUEST', null)
    },
    saveUpdatedService(service) {
      showLoader()
      const { updateServiceForEditById } = useProviderUi()

      updateServiceForEditById(service.id)
        .then(({ data }) => {
          if (data) {
            this.showCreateNewService = false
            this.refreshGridArea()
            eventBus.$emit('refresh-on-going-services-socket', {
              userId: service.user_id,
              serviceId: service.id,
              service: 'SERVICE',
            })
          } else {
            showDangerNotification(this, data.message ? data.message : 'Failed to get service for edit')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    editService(service) {
      showLoader()
      const { getServiceForEditById } = useProviderUi()

      getServiceForEditById(service.id)
        .then(({ data }) => {
          if (data) {
            // eslint-disable-next-line no-param-reassign
            service.requestDetail = data.request
            this.$store.commit('app-ecommerce/UPDATE_PROVIDER_SERVICE_REQUEST', service)
            this.$store.commit('app-ecommerce/UPDATE_EDIT_PROVIDER_SERVICE_REQUEST', data.cart)
            this.showCreateNewService = true
          } else {
            showDangerNotification(this, data.message ? data.message : 'Failed to get service for edit')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getFiltered($event) {
      let status = ''
      if ($event && $event.target && $event.target.value) {
        status = $event.target.value
      }
      this.$refs.myRequestsGrid.loadItems(status ? `status=${status}` : '')
    },
    copyUrl() {
      if (window.SuperApp.helper.copyUrl(this.storeUrl)) {
        showSuccessNotification(this, 'Link copied successfully')
      } else {
        showDangerNotification(this, 'Failed to copy link')
      }
    },
    showOnMap(props) {
      this.showAgentOnMap = true
      this.showOnMapAgent = props.id
    },
    refreshGridArea() {
      this.$refs.myRequestsGrid.loadItems()
    },
    createServiceModal() {
      this.showCreateNewService = true
    },
    inputFile(payload) {
      this.serviceHistoryProps.updateRequest.beforePickup = payload
    },
    updateRequestApproval(props, status) {
      this.resetRequestUpdateStatus()
      // this.serviceHistoryProps.viewUpdateRequestModal = true
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.updateRequest.selectedStatus = status
      this.updateRequestStatus()
    },
    resetRequestUpdateStatus() {
      this.serviceHistoryProps.updateRequest = {
        selectedStatus: '',
        notes: '',
        scheduleDate: '',
        assignedAgent: '',
        beforePickup: null,
      }
    },
    rescheduleRequest() {
      this.serviceHistoryProps.upcomingServiceDetailVisible = false
      this.serviceHistoryProps.viewUpdateRequestModal = true
      this.serviceHistoryProps.updateRequest.selectedStatus = 'SCHEDULED'
    },
    uploadImageService(payload) {
      this.serviceHistoryProps.upcomingServiceDetailVisible = false
      this.serviceHistoryProps.viewUpdateRequestModal = true
      this.serviceHistoryProps.updateRequest.selectedStatus = payload.status
    },
    updateRequestStatus() {
      // eslint-disable-next-line no-unreachable
      let formData = new FormData()
      let date = ''
      let year = ''
      let time = ''
      let month = ''
      let day = ''
      if (this.$refs.calenderRef) {
        this.serviceHistoryProps.updateRequest.scheduleDate = new Date(`${this.$refs.calenderRef.$data.activeDate.toDateString()} ${this.$refs.calenderRef.$data.activeTimeSlot}`)
      }
      if (this.serviceHistoryProps.updateRequest.scheduleDate) {
        const splitDate = this.serviceHistoryProps.updateRequest.scheduleDate
        date = splitDate
        year = new Date(date).getFullYear()
        month = new Date(date).getMonth() + 1
        day = new Date(date).getDate()
        date = `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(date).getFullYear()}`
        if (this.$refs.calenderRef) {
          time = this.$refs.calenderRef.$data.activeTimeSlot
        }
      }
      const updateRequestDTO = this.serviceHistoryProps.currentServiceForDispute
      formData.append('id', updateRequestDTO.id)
      formData.append('user_id', updateRequestDTO.user_id)
      formData.append('provider_id', updateRequestDTO.provider_id)
      formData.append('status', this.serviceHistoryProps.updateRequest.selectedStatus)
      formData.append('agent_id', this.serviceHistoryProps.updateRequest.assignedAgent)
      formData.append('date', date)
      formData.append('time', time)
      formData.append('year', year)
      formData.append('day', day)
      formData.append('month', month)
      if (this.serviceHistoryProps.updateRequest.beforePickup) {
        formData.append(
          // eslint-disable-next-line no-nested-ternary
          this.serviceHistoryProps.updateRequest.selectedStatus === 'PICKEDUP' ? 'before_picture' : this.serviceHistoryProps.updateRequest.selectedStatus === 'DROPPED' ? 'after_picture' : '',
          this.serviceHistoryProps.updateRequest.beforePickup,
        )
      }
      formData.append('comments', this.serviceHistoryProps.updateRequest.notes)
      formData.append('admin_service', 'SERVICE')
      showLoader()
      const { updateProviderRequest } = useProviderUi()
      let requestUrl = ''
      switch (this.serviceHistoryProps.updateRequest.selectedStatus) {
        case 'ASSIGNED':
          requestUrl = 'provider/agent/assign'
          break
        case 'SCHEDULED':
          requestUrl = 'provider/service/reschedule'
          break
        case 'CANCELLED':
          requestUrl = 'provider/cancel/serve/request'
          break
        default:
          requestUrl = 'provider/update/serve/request'
      }
      if (this.serviceHistoryProps.updateRequest.selectedStatus === 'CANCELLED') {
        formData = new FormData()
        formData.append('id', updateRequestDTO.id)
        formData.append('admin_service', 'SERVICE')
        formData.append('comments', this.serviceHistoryProps.updateRequest.notes)
      }
      updateProviderRequest({
        query: requestUrl,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Request updated successfully')
            this.$refs.myRequestsGrid.loadItems()
            this.getProviderRequestList()
            eventBus.$emit('refresh-on-going-services-socket', {
              userId: this.serviceHistoryProps.currentServiceForDispute.user_id,
              serviceId: updateRequestDTO.id,
              service: 'SERVICE',
            })
            if (this.serviceHistoryProps.updateRequest.selectedStatus === 'ASSIGNED') {
              eventBus.$emit('provider-sync-app', {
                agentId: this.serviceHistoryProps.updateRequest.assignedAgent,
                showIncomingRequest: true,
              })
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    orderCreated() {
      this.showCreateNewService = false
      this.$refs.myRequestsGrid.loadItems()
      this.getProviderRequestList()
    },
    hideUpdateRequestModal() {
      this.serviceHistoryProps.viewUpdateRequestModal = false
    },
    viewUpdateRequestModal(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.viewUpdateRequestModal = true
    },
    viewRequestHistory(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.viewHistoryModal = true
      this.getRequestHistory()
    },
    hideRequestHistoryModal() {
      this.serviceHistoryProps.requestHistoryList = []
      this.serviceHistoryProps.viewHistoryModal = false
    },
    getRequestHistory() {
      showLoader()
      this.serviceHistoryProps.requestHistoryList = []
      const { providerRequestHistoryList } = useProviderUi()
      providerRequestHistoryList(this.serviceHistoryProps.currentServiceForDispute.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceHistoryProps.requestHistoryList = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    hideUpcomingServiceModal() {
      this.serviceHistoryProps.upcomingServiceDetailVisible = false
    },
    viewServiceHistory(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.upcomingServiceDetailVisible = true
      this.getTripHistory()
    },
    createServiceDispute(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.viewDisputeModal = true
      this.getDisputeStatus()
    },
    getDisputeStatus() {
      showLoader()
      this.serviceHistoryProps.disputeObject = {}
      const { providerDisputeStatus } = useProviderUi()
      providerDisputeStatus(this.serviceHistoryProps.currentServiceForDispute.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceHistoryProps.disputeObject = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    submitServiceDispute() {
      if (this.disputeObject) return
      const formData = new FormData()
      formData.append('referral_email', '')
      formData.append('dispute_type', 'provider')
      formData.append('dispute_name', this.serviceHistoryProps.currentDispute)
      formData.append('comments', this.serviceHistoryProps.currentDispute === 'Others' ? this.serviceHistoryProps.disputeReason : '')
      formData.append('id', this.serviceHistoryProps.currentServiceForDispute.id)
      formData.append('user_id', this.serviceHistoryProps.currentServiceForDispute.user_id)
      formData.append('provider_id', this.serviceHistoryProps.currentServiceForDispute.provider_id)

      const { createServiceDispute } = useProviderUi()
      showLoader()
      createServiceDispute(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getTripHistory() {
      showLoader()
      this.serviceHistoryProps.tripHistoryObject = {}
      const { providerRequestDetail } = useProviderUi()
      providerRequestDetail(this.serviceHistoryProps.currentServiceForDispute.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceHistoryProps.tripHistoryObject = data.responseData.service
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getCategoryName(props) {
      const items = this.getCategoryArray(props)
      if (items && items.length > 0) {
        return items.map(x => `${x.service}`).join(', ')
      }
      return 'N/A'
    },
    getCategoryArray(props) {
      if (props.items && props.items.length > 0) {
        return props.items
          .filter(x => x.provider_service && x.provider_service.main_service && x.provider)
          .map(x => ({
            service: !isEmpty(x.provider_service.title) ? x.provider_service.title : x.provider_service.main_service.service_name,
            total_service_price: x.total_service_price,
            currency_symbol: x.provider.currency_symbol,
            category: x.provider_service.main_service.service_category ? x.provider_service.main_service.service_category.service_category_name : '',
          }))
      }
      return []
    },
    getServicePrice(props) {
      if (props.price) {
        const providerCurrency = props.items && props.items.length > 0 && props.items[0].provider ? props.items[0].provider.currency_symbol : ''
        return `${props.price} ${providerCurrency}`
      }
      return 'N/A'
    },
    hideDisputeModal() {
      this.serviceHistoryProps.viewDisputeModal = false
    },
    getProviderRequestList() {
      const { providerRequestList } = useProviderUi()

      providerRequestList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerAllRequest = data.responseData.service.data
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

#requestHistoryModal .modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

#updateRequestModal .modal-body {
  min-height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
}

#createServiceModal .vue-phone-number-input {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.grid-view {
  overflow: auto;
  white-space: nowrap;
  max-width: 100%;
  padding: 10px;
}
</style>

<style>
body .dashboard-store-icons {
  height: 38px;
}
.store-profile-3-icons{
  width: 22px
}
@media only screen and (max-width: 767px) {
  .store-profile-3-icons{
    width: 16px
  }
  body .dashboard-store-icons {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    height: auto;
  }

  body .dashboard-store-icons .custom-icons {
    margin: 0 !important;
    padding: 5px !important;
  }
}

</style>
